.column {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 15px;
  border-radius: 15px;
}
.column_title {
  font-size: 24px;
  padding: 15px 0px;
  margin-bottom: 10px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  border-bottom: 1px solid grey;
}