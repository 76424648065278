.order_card {
  width: calc(100% - 40px);
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px 18px 18px 18px;
  margin-bottom: 23px;
  box-shadow: 0px 4px 8px 0px #0000001F;
}
.order_card--FULFILL {
  background-color: #c1c1c1;
}
.order_card_header {
  display: flex;
  flex-wrap: wrap;
  width: 60%;
}
.order_card_header_name,
.order_card_header_finance {
  padding: 4px 10px;
  font-size: 13px;
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 6px;
  background-color: #DEDEDE;
  color: #333333;
  text-decoration: none;
}
.order_card_date {
  width: 40%;
  margin: 0px;
  text-align: right;
  margin-top: 6px;
  font-size: 12px;
}
.order_card_total {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 0px 10px 0px;
}
.order_card_total_qty {
  font-size: 18px;
  font-weight: bold;
  margin-left: 5px;
}

.order_card_subheader {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 20px 0px;
}

.order_card_items {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.order_card_items_item {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 10px 0px;
  background-color: #FBF7EC;
  border: 2px solid #FBF7EC;
}
.order_card_items_item--picked {
  border: 2px solid green;
}
.order_card_items_item_image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  flex-direction: row;
}
.order_card_items_item_detail {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}
.order_card_items_item_detail > p {
  margin: 0px;
}

.items_item_tags {
  display: flex;
  flex-wrap: wrap;
  margin: 0px 0px 20px 0px;
}
.items_item_tag {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0px 9px 0px 0px;
}
.items_item_type {
  color: #333333;
  background-color: #FFDDCE;
}
.items_item_solidgold {
  color: #333333;
  background-color: #FFF298;
}

.items_item_details,
.items_item_sku {
  margin: 0px;
}

.items_item_details {
  display: flex;
  align-items: center;
}
.items_item_details_qty {
  font-size: 18px;
  font-weight: bold;
  color: #ff532b;
}
.items_item_details_name {
  font-size: 16px;
  margin-left: 4px;
  font-weight: bold;
}
.items_item_details_name_size_value {
  font-size: 20px;
  font-weight: bold;
  margin-left: 4px;
}
.items_item_details_name_sub {
  font-size: 14px;
  margin-left: 10px;
  color: #878787;
}
.items_item_sku {
  margin-top: 5px;
  font-size: 12px;
  color: #878787;
}
.items_item_done {
  position: absolute;
  right: 22px;
  font-size: 20px;
  top: 17px;
}

.order_card_note,
.order_card_actions,
.order_card_packer,
.order_card_customer,
.order_card_total {
  width: 100%;
  text-align: left;
  margin: 0px;
}
.order_card_actions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.order_card_actions_btn {
  cursor: pointer;
  font-size: 14px;
  margin: 0px;
  border-radius: 22px;
  padding: 0px 14px;
  height: 35px;
  min-width: 110px;
  font-weight: bold;
  border: 1px solid;
  height: 44px;
}
.order_card_actions_btn--unfulfill {
  background-color: #ff3a0c;
}
.order_card_actions_btn--unfulfill:hover {
  background-color: #FFF;
  border: 1px solid #ff3a0c;
  color: #ff3a0c;
}
.order_card_actions_btn--fulfill,
.order_card_actions_btn--next {
  background-color: #333333;
  border: 1px solid #333333;
  color: #fff;
}
.order_card_actions_btn--fulfill:hover,
.order_card_actions_btn--next:hover {
  background-color: #fff;
  color: #000;
}
.order_card_actions_btn--fulfill:disabled {
  background-color: #dfdfdf;
  border: 1px solid #949494;
  color: #949494;
}
.order_card_actions_btn--previous {
  background-color: #f4f4f4;
}
.order_card_note {
  margin-bottom: 30px;
}
.order_card_stylist,
.order_card_packer {
  color: #333333;
  margin: 16px 0px 0px 0px;
  font-style: italic;
  text-align: right;
  width: 50%;
}
.order_card_stylist {
  text-align: left;
  font-size: 12px;
}

