.users_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 25px;
}
.users_container_user {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #e6f3ff;
  border: 1px solid #c2c2c2;
  border-radius: 20px;
}
.users_container_user_img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.users_container_user_info {
  margin-left: 15px;
  width: 50%;
}
.users_container_user_info > p {
  margin: 0px;
}
.users_container_user_roles,
.users_container_user_stores {
  position: relative;
  width: 25%;
  margin: 0px 10px;
}


.users_container_user_roles {

}
.users_container_user_roles_title {
  font-size: 12px;
  font-style: italic;
  color: grey;
}
.user_roles {
  display: flex;
  flex-wrap: wrap;
  padding-right: 30px;
}
.user_roles_role {
  background-color: #FFF;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.user_roles_role_remove {
  width: 14px;
  height: 14px;
  border: 1px solid #000;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  margin-left: 10px;
  cursor: pointer;
}
.user_roles_role_remove > img {
  width: 8px;
  display: flex;
}
.user_roles_user {

}
.user_roles_add {
  position: absolute;
  top: 0px;
  right: 0px;
}
.user_roles_add_icon {
  background-color: #FFF;
  border: 1px solid #000;
  width: 20px;
  height: 20px;
  font-size: 19px;
  line-height: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
  position: relative;
}
.user_roles_add_detail {
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  right: 0px;
  background-color: #FFF;
  border: 1px solid #000;
  width: 220px;
  top: 30px;
  border-radius: 10px;
  padding: 10px;
  z-index: 9;
}