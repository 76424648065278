
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ecf7ff;
  border: 1px solid #b1b1b1;
  box-shadow: 7px 6px 12px #f7f7f7;
  border-radius: 25px;
  width: 40%;
  padding: 100px 0px;
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 30%;
  right: 30%;
}
.login_logo {
  max-width: 80px;
}
.login_content {

}
.login_button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  padding: 0px 30px;
  border-radius: 10px;
  border: 1px solid #000;
  background-color: #000;
  color: #FFF;
  cursor: pointer;
  font-size: 14px;
}
.login_button > img {
  margin-right: 25px;
}
.login_button:hover {
  background-color: #fff;
  color: #000;
}
