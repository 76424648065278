.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu > div,
.menu > a,
.menu > select {
  margin: 0px 10px;
}
.menu_options {
  display: flex;
}
.menu_options_main {
  max-width: 20px;
}
.menu > select {
  border: 1px solid #000;
  border-radius: 20px;
  padding: 6px 12px;
}

.browser_notification {
  position: relative;
  cursor: pointer;
}
.browser_notification_icon {
  width: 22px;
}
.browser_notification_denied, 
.browser_notification_granted {
  border-radius: 100%;
  width: 9px;
  height: 9px;
  border: 1px solid #000;
  position: absolute;
  right: 0px;
  top: 0px;
 }
.browser_notification_granted {
  background-color: var(--active-green);
}
.browser_notification_denied {
  background-color: red;
}
.browser_notification_detail {
  display: none;
}
.browser_notification:hover > .browser_notification_detail {
  display: block;
  background-color: #FFF;
  border: 1px solid #000;
  width: 120px;
  position: absolute;
  top: 20px;
  left: -5px;
  padding: 10px;
  z-index: 10;
  font-size: 13px;
  border-radius: 11px;
}
