:root {
  --logout-bg-color: #000;
  --active-green: rgb(26, 223, 26);
}
.app {
  position: relative;
  text-align: center;
}

.logout {
  height: 42px;
  padding: 0px 10px 0px 6px;
  border-radius: 25px;
  border: 1px solid #000;
  background-color: var(--logout-bg-color);
  color: #FFF;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.logout:hover {
  --logout-bg-color: #FFF;
  color: #000;
}
.image_container {
  position: relative;
}
.logout_image {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
}
.connected_status {
  position: absolute;
  bottom: 0px;
  right: 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: 2px solid var(--logout-bg-color);
}

.connected_status--connected {
  background-color: var(--active-green);
}
.connected_status--disconnected {
  background-color: red;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #dddddd;
  box-shadow: 2px 2px 4px #c2c2c2;
}