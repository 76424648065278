
@-moz-keyframes spinLoading { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spinLoading { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spinLoading { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); } }

.al_loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20vw;
  background-color: #fff;
  border-radius: 16px;
  padding: 35px 20px;
  margin: 0 auto;
}
.al_loading_icon {
  display: flex;
  justify-content: center;
  -webkit-animation: spinLoading 2.3s linear infinite;
  -moz-animation: spinLoading 2.3s linear infinite;
  animation: spinLoading 2.3s linear infinite;
  color: #484848;
  font-size: 35px;
}
.al_loading_icon--reverse {
  animation-direction: reverse;
}
.al_loading_icon--fast {
  animation-duration: 1s;
}
.al_loading_text {
  text-align: center;
  margin-bottom: 0px;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 20px 20px 0px 20px;
}
.al_loading_text_line {
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 24px;
  margin: 0;
}