.title {
  display: flex;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.title_tag {
  margin-left: 12px;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
}