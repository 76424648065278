.orders_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}
.orders_container_content {
  background-color: #F0F2F3;
  border-radius: 16px;
  padding: 20px;
  margin-top: 10px;
}
.view_button {
  border: 1px solid;
  border-radius: 15px;
  padding: 4px 18px;
  font-size: 12px;
  margin: 0px 0px 0px 10px;
  height: 24px;
}
.view_button--selected {
  background-color: #353535;
  border: 1px solid #353535;
  color: #fff;
}